import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { VacationStatusTag } from '../Status';

import { PayrollActions } from './PayrollActions';

export const payrollActionsColumn = (
  refetchAll: () => Promise<void>,
): GridColDef => ({
  sortable: false,
  field: 'action',
  headerName: 'Próxima ação',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    return <PayrollActions row={value} refetchAll={refetchAll} />;
  },
});

export const payrollStatusColumn: GridColDef = {
  sortable: false,
  field: 'vacationsPayrollStatus',
  headerName: 'Status cálculo',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    return <VacationStatusTag summary={value} isPayroll />;
  },
};
