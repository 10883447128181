import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { MONTHS_BR } from '@octopus/formatters';
import { VacationsConfigurationNonDeductibleDay } from '@octopus/api';

function getDaysInMonth(month: number): number {
  if (month < 1 || month > 12) return 31;
  const monthString = String(month).padStart(2, '0');
  return dayjs(`2001-${monthString}-01`, 'YYYY-MM-DD').daysInMonth();
}

export function UnionExtraDaysConfig({
  config,
  onChange,
}: {
  config?: VacationsConfigurationNonDeductibleDay[];
  onChange: (updated: VacationsConfigurationNonDeductibleDay[]) => void;
}) {
  const items = config ?? [];
  const [errors, setErrors] = useState<Record<number, Record<string, string>>>(
    {},
  );

  const validateItem = (
    item: VacationsConfigurationNonDeductibleDay,
    index: number,
  ) => {
    const errorMessages: Record<string, string> = {};

    if (!item.day || item.day < 1 || item.day > getDaysInMonth(item.month)) {
      errorMessages.day = 'O dia é inválido para o mês selecionado.';
    }

    if (!item.start) {
      errorMessages.start = 'O campo "Início da vigência" é obrigatório.';
    }

    if (!item.end) {
      errorMessages.end = 'O campo "Fim da vigência" é obrigatório.';
    }

    if (item.start && item.end && dayjs(item.start).isAfter(dayjs(item.end))) {
      errorMessages.end = 'A data de fim deve ser posterior à data de início.';
    }

    if (Object.keys(errorMessages).length > 0) {
      setErrors((prev) => ({ ...prev, [index]: errorMessages }));
      return false;
    }

    setErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[index];
      return updatedErrors;
    });

    return true;
  };

  const updateItems = (newItems: VacationsConfigurationNonDeductibleDay[]) => {
    newItems.forEach((item, index) => validateItem(item, index));
    onChange(newItems);
  };

  const handleAdd = () => {
    const newItem: VacationsConfigurationNonDeductibleDay = {
      day: 1,
      month: 1,
      onlyWeekdays: false,
      start: '',
      end: '',
    };
    updateItems([...items, newItem]);
  };

  const handleRemove = (index: number) => {
    const updated = items.filter((_, i) => i !== index);
    updateItems(updated);
  };

  const handleChangeField = (
    index: number,
    field: keyof VacationsConfigurationNonDeductibleDay,
    value: string | number | boolean,
  ) => {
    const updated = [...items];
    updated[index] = { ...updated[index], [field]: value };
    validateItem(updated[index], index);
    updateItems(updated);
  };

  return (
    <Accordion
      className="borderless"
      defaultExpanded={false}
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: 1,
        height: '100%',
        border: 'none',
        width: '100%',
        px: 2,
        py: 1,
        boxSizing: 'border-box',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Box display="flex">
          <Typography variant="h3" gutterBottom>
            Dias não contabilizados nas férias por acordo coletivo
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {items.map((item, index) => {
          const daysInCurrentMonth = getDaysInMonth(item.month);
          const itemErrors = errors[index] || {};

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              border="1px solid #ccc"
              borderRadius={1}
              p={2}
              mb={2}
            >
              <Typography variant="subtitle1" gutterBottom>
                Dia #{index + 1}
              </Typography>

              <Box display="flex" gap={2} mb={2}>
                <FormControl size="small" error={!!itemErrors.day}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Dia
                  </Typography>
                  <Select
                    value={item.day}
                    onChange={(e) =>
                      handleChangeField(index, 'day', Number(e.target.value))
                    }
                    sx={{ width: 80 }}
                  >
                    {Array.from(
                      { length: daysInCurrentMonth },
                      (_, i) => i + 1,
                    ).map((d) => (
                      <MenuItem key={d} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                  {itemErrors.day && (
                    <FormHelperText>{itemErrors.day}</FormHelperText>
                  )}
                </FormControl>

                <FormControl size="small">
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Mês
                  </Typography>
                  <Select
                    value={item.month}
                    onChange={(e) => {
                      const newMonth = Number(e.target.value);
                      handleChangeField(index, 'month', newMonth);
                    }}
                    sx={{ width: 150 }}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                      <MenuItem key={m} value={m}>
                        {MONTHS_BR[m - 1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" gap={2}>
                <FormControl size="small" error={!!itemErrors.start}>
                  <TextField
                    label="Início da vigência"
                    type="date"
                    value={item.start}
                    onChange={(e) =>
                      handleChangeField(index, 'start', e.target.value)
                    }
                    sx={{ my: 1, width: 250 }}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  {itemErrors.start && (
                    <FormHelperText>{itemErrors.start}</FormHelperText>
                  )}
                </FormControl>

                <FormControl size="small" error={!!itemErrors.end}>
                  <TextField
                    label="Fim da vigência"
                    type="date"
                    value={item.end}
                    onChange={(e) =>
                      handleChangeField(index, 'end', e.target.value)
                    }
                    sx={{ my: 1, width: 250 }}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  {itemErrors.end && (
                    <FormHelperText>{itemErrors.end}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              <FormControlLabel
                label="Considerar somente dia da semana?"
                control={
                  <Checkbox
                    checked={item.onlyWeekdays}
                    onChange={(e) =>
                      handleChangeField(index, 'onlyWeekdays', e.target.checked)
                    }
                  />
                }
                sx={{ my: 1, px: 0.5 }}
              />

              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemove(index)}
                sx={{ mt: 1, maxWidth: 180 }}
              >
                Remover
              </Button>
            </Box>
          );
        })}

        <Button variant="contained" onClick={handleAdd}>
          Adicionar Novo
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}
