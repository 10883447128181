import React from 'react';

import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

function Field({
  value,
  onChange,
  type,
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  type: 'number' | 'text';
}) {
  return (
    <TextField
      margin="dense"
      value={value}
      type={type}
      onChange={(e) =>
        onChange(type === 'number' ? Number(e.target.value) : e.target.value)
      }
    />
  );
}

function SelectField({
  value,
  onChange,
  options,
}: {
  value: string | boolean;
  onChange: (value: any) => void;
  options: { value: any; label: string }[];
}) {
  return (
    <Select
      margin="dense"
      value={value}
      fullWidth
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function CheckFieldGroup({
  value,
  onChange,
  options,
}: {
  value: string[];
  onChange: (value: string[]) => void;
  options: { value: string; label: string }[];
}) {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        {options.map((option) => {
          const checked = value.includes(option.value);
          return (
            <Box display="flex" alignItems="center" key={option.value}>
              <Checkbox
                checked={checked}
                sx={{ paddingX: 0 }}
                onChange={(e) => {
                  let newValue = [...value];
                  if (e.target.checked) {
                    newValue.push(option.value);
                  } else {
                    newValue = newValue.filter((val) => val !== option.value);
                  }
                  onChange(newValue);
                }}
              />
              <Typography variant="body2" px={1}>
                {option.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export const LABEL_MAP: Record<string, string> = {
  paymentDateConfiguration: 'Configuração de Pagamento',
  approvalConfiguration: 'Configuração de Aprovação',
  unionExtraDays: 'Dias não contabilizados',
  day: 'Dia',
  month: 'Mês',
  onlyWeekdays: 'Somente dias de semana',
  start: 'Data inicial',
  end: 'Data final',
  steps: 'Etapa',
  roles: 'Aprovadores',
  min: 'Mínimo',
  max: 'Máximo',
  days: 'Quantidade de dias',
  internal: 'Colaborador',
  ['owner']: 'Administradores',
  ['tako:support']: 'Tako',
  minimum: 'Mínimo',
  maximum: 'Máximo',
};

export { Field, SelectField, CheckFieldGroup };
