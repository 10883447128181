import {
  VacationsDetailsTabs,
  VacationsDetailsTabsConfig,
} from '../../utils/types';

export const vacationsDetailsTabsConfig: VacationsDetailsTabsConfig = {
  [VacationsDetailsTabs.available]: {
    key: VacationsDetailsTabs.available,
    label: 'Dias disponíveis',
  },
  [VacationsDetailsTabs.requests]: {
    key: VacationsDetailsTabs.requests,
    label: 'Solicitações',
    emptyMessage: 'Não existem solicitações para esta pessoa neste momento.',
  },
  [VacationsDetailsTabs.calculations]: {
    key: VacationsDetailsTabs.calculations,
    label: 'Calculos',
    emptyMessage: 'Não existem calculos para esta pessoa neste momento.',
  },
  [VacationsDetailsTabs.history]: {
    key: VacationsDetailsTabs.history,
    label: 'Histórico',
    emptyMessage: 'Esta pessoa ainda não usufruiu de férias.',
  },
};
