import { Box, Typography } from '@mui/material';

import { ExpiredIcon } from '../ExpiredIcon';

export const VacationsBalance = ({
  totalDaysAvailable,
  hasOverdueVacations,
}: {
  totalDaysAvailable: number;
  hasOverdueVacations: string;
}) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="start">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: hasOverdueVacations && theme.palette.error.lowEmphasis,
          })}
        >
          {`${totalDaysAvailable} dias`}
        </Typography>
      </Box>
      {hasOverdueVacations && (
        <Box ml={2}>
          <ExpiredIcon totalDaysAvailable={totalDaysAvailable} />
        </Box>
      )}
    </Box>
  );
};
