import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { VacationsConfigurationList } from '@octopus/api';
import { CheckFieldGroup, SelectField } from './fields';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { IconInfoSquareRounded } from '@tabler/icons-react';

type Props = {
  config: VacationsConfigurationList['paymentDateConfiguration'];
  onChange: (
    updatedPaymentDateConfig: VacationsConfigurationList['paymentDateConfiguration'],
  ) => void;
};

export function VacationsPaymentConfiguration({ config, onChange }: Props) {
  const [startDate, setStartDate] = useState<string | null>(null);

  if (!config) {
    return null;
  }

  const updateField = (field: keyof typeof config, value: unknown) => {
    onChange({
      ...config,
      [field]: value,
    });
  };

  const calculateTimelineDates = () => {
    if (!startDate || !dayjs(startDate, 'YYYY-MM-DD', true).isValid()) {
      return [];
    }

    const startDay = dayjs(startDate, 'YYYY-MM-DD', true);
    const paymentDate = findNearestDayOfWeek(startDay.subtract(
      config.amountOfDaysBeforeStart || 0,
      'day',
    ), config.paymentWeekDays);
    const approvalDeadline = paymentDate.subtract(
      config.approvalDeadlineThresholdDays || 0,
      'day',
    );
    const payrollCalculationDate = paymentDate.subtract(
      config.daysBeforePaymentDateToCreatePayroll || 0,
      'day',
    );

    const dates = [
      { label: 'Início das Férias', date: startDay },
      { label: 'Data de Geração da Folha', date: payrollCalculationDate },
      { label: 'Data de Pagamento', date: paymentDate },
      { label: 'Prazo de Aprovação do pedido', date: approvalDeadline },
      { label: 'Hoje', date: dayjs() },
    ];

    return dates.sort((a, b) => a.date.diff(b.date));
  };

  const timelineDates = calculateTimelineDates();

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: 1,
        border: 'none',
        height: '100%',
        width: '100%',
        px: 2,
        py: 1,
        boxSizing: 'border-box',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Typography variant="h3">Data de pagamento</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box display={'flex'} gap={2}>
          <Box width={'70%'}>
            <TableContainer
              sx={{ border: '1px solid #EDEDED', borderRadius: 1 }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width={'70%'}>
                      <Typography variant={'body2'}>
                        Data de pagamento é gerada para quantos dias antes do
                        início das férias?
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        margin="dense"
                        value={config.amountOfDaysBeforeStart ?? ''}
                        type={'number'}
                        onChange={(e) =>
                          updateField(
                            'amountOfDaysBeforeStart',
                            Number(e.target.value),
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Dias úteis ou corridos?</TableCell>
                    <TableCell>
                      <SelectField
                        value={config.typeOfDays ?? ''}
                        onChange={(e) => updateField('typeOfDays', e)}
                        options={[
                          { value: 'calendar', label: 'Corridos' },
                          { value: 'banking', label: 'Úteis' },
                        ]}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Admins podem alterar?</TableCell>
                    <TableCell>
                      <SelectField
                        value={config.adminsAllowedToChange ?? false}
                        onChange={(e) =>
                          updateField('adminsAllowedToChange', e)
                        }
                        options={[
                          { value: true, label: 'Sim' },
                          { value: false, label: 'Não' },
                        ]}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant={'body2'}>
                        Quantos dias antes da data de pagamento o pedido de
                        férias deve ser aprovado?
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        margin="dense"
                        value={config.approvalDeadlineThresholdDays ?? ''}
                        type={'number'}
                        onChange={(e) =>
                          updateField(
                            'approvalDeadlineThresholdDays',
                            Number(e.target.value),
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        gap={1}
                      >
                        <Typography variant={'body2'} width={'80%'}>
                          Quantos dias antes da data de pagamento o cálculo da
                          folha de férias é gerado?
                        </Typography>
                        <Tooltip
                          title={
                            'Caso o pedido seja aprovado após a data que for calculada por essa configuração, o cálculo é gerado automáticamente.'
                          }
                        >
                          <IconInfoSquareRounded color={'#1E78FF'} />
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        margin="dense"
                        type={'number'}
                        value={
                          config.daysBeforePaymentDateToCreatePayroll ?? ''
                        }
                        onChange={(e) =>
                          updateField(
                            'daysBeforePaymentDateToCreatePayroll',
                            Number(e.target.value),
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Dias da semana que são feitos pagamentos
                    </TableCell>
                    <TableCell>
                      <CheckFieldGroup
                        value={config.paymentWeekDays ?? []}
                        onChange={(val) => updateField('paymentWeekDays', val)}
                        options={[
                          { value: 'monday', label: 'Segunda' },
                          { value: 'tuesday', label: 'Terça' },
                          { value: 'wednesday', label: 'Quarta' },
                          { value: 'thursday', label: 'Quinta' },
                          { value: 'friday', label: 'Sexta' },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            bgcolor={'white'}
            p={2}
            border={'1px solid #EDEDED'}
            borderRadius={1}
            width={'40%'}
          >
            <Box p={1}>
              <Typography variant="h6" gutterBottom>
                Simulação
              </Typography>
            </Box>
            <TextField
              label="Data de Início das Férias"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate || ''}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ width: '100%', my: 2 }}
            />

            <Timeline>
              {timelineDates.map(({ label, date }, idx) => (
                <TimelineItem key={label}>
                  <TimelineOppositeContent color="text.secondary">
                    <Typography variant="caption" fontWeight={700}>
                      {label}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        idx === timelineDates.length - 1 ? 'success' : 'primary'
                      }
                    />
                    {idx < timelineDates.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="caption">
                      {date.format('DD/MM/YYYY')}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

type PaymentWeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

function findNearestDayOfWeek(ref: Dayjs, paymentWeekDays: PaymentWeekDay[]): Dayjs {
  const dayMap: { [key in PaymentWeekDay]: number } = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const refDate = dayjs(ref);
  const refDayOfWeek = refDate.day();

  // Filtra os dias que são antes ou iguais ao dia atual
  const filteredDays = paymentWeekDays.filter(
    (weekDay) => dayMap[weekDay] <= refDayOfWeek,
  );

  // Determina o dia mais próximo
  const closestWeekDay =
    filteredDays.length > 0
      ? filteredDays.at(-1)
      : paymentWeekDays.at(-1);

  if (!closestWeekDay) {
    return refDate;
  }

  const closestDayNumber = dayMap[closestWeekDay];
  return refDate.day(closestDayNumber <= refDayOfWeek ? closestDayNumber : closestDayNumber - 7);
}