import { useContext, useState } from 'react';

import { Submission } from '@conform-to/react';
import { Schema } from 'zod';

import { CheckCircle, Close } from '@mui/icons-material';

import {
  VacationsScheduleEntry,
  VacationsScheduleReviewInput,
  VacationsScheduleSummary,
  fetchPostReviewVacationsSchedule,
} from '@octopus/api';
import { membershipTypes } from '@octopus/vacations-types';
import { workflowRoles } from '@octopus/workflows-types-v2';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { AppContext } from '../../context';
import { RejectModal } from '../[contractId]/new/modal/ScheduleRejectModal';

export const useVacationsSchedule = (
  summary: VacationsScheduleSummary | VacationsScheduleEntry,
  onReview: () => Promise<void>,
  isManager: boolean,
) => {
  const { organizationId, contractId, sequence } = summary;

  const [isOpen, setIsOpen] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useSnackbar();

  const pathParams = {
    sequence: `${sequence}`,
    organizationId,
    contractId,
  };

  const openRejectModal = () => {
    setIsOpen('reprove');
  };

  const onStartReview = () => {
    setIsSubmitting(true);
  };

  const onFinishReview = () => {
    setIsSubmitting(false);
    setIsOpen(false);
  };

  const fetchReviewVacationsSchedule = ({
    sequence,
    action,
    comment,
    role,
    override,
  }: {
    sequence: string;
  } & VacationsScheduleReviewInput) =>
    fetchPostReviewVacationsSchedule({
      pathParams: { sequence, ...pathParams },
      body: {
        action,
        comment,
        role,
        override,
      },
    });

  const reproveVacation =
    (override = false, asManager = false) =>
    (_: any, formData: Submission<Schema, string[], Schema>) => {
      onStartReview();
      fetchReviewVacationsSchedule({
        action: 'reject',
        sequence: `${sequence}`,
        comment: formData.payload.rejectReason as string,
        role: isManager || asManager ? 'manager' : 'membership:owner',
        override,
      })
        .then(() => onReview())
        .then(() => onFinishReview())
        .catch(() => {
          setIsSubmitting(false);
          showSnackbar({
            isOpen: true,
            variant: 'error',
            Message: 'Erro ao reprovar férias',
            StartAdornment: <Close />,
            hasCloseAction: true,
          });
        });
    };

  const approveVacation = (override: boolean, asManager: boolean) => {
    onStartReview();
    fetchReviewVacationsSchedule({
      action: 'approve',
      sequence: `${sequence}`,
      role: isManager || asManager ? 'manager' : 'membership:owner',
      override,
    })
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Férias aprovadas com sucesso',
          StartAdornment: <CheckCircle />,
          hasCloseAction: true,
        });
      })
      .then(() => onReview())
      .then(() => onFinishReview())
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao aprovar férias',
          StartAdornment: <Close />,
          hasCloseAction: true,
        });
        setIsSubmitting(false);
      });
  };

  const RejectScheduleModal = (props: {
    override: boolean;
    asManager: boolean;
  }) => {
    const { override, asManager } = props;

    if (isOpen !== 'reprove') {
      return null;
    }

    return (
      <RejectModal
        open={isOpen === 'reprove'}
        onCancel={() => setIsOpen(false)}
        onConfirm={reproveVacation(override, asManager)}
        isSubmitting={isSubmitting}
      />
    );
  };

  return {
    RejectScheduleModal,
    approveVacation,
    reproveVacation,
    isSubmitting,
    openRejectModal,
  };
};

export const useGetRequestedRoles = (
  vacationSchedule: VacationsScheduleSummary | VacationsScheduleEntry,
  isManager: boolean,
) => {
  const { approval } = vacationSchedule;
  const { appContext } = useContext(AppContext);
  const {
    membership: { membershipType },
  } = appContext;
  const isAdmin =
    membershipType === membershipTypes.owner ||
    membershipType === membershipTypes['tako:support'];

  if (!approval?.steps?.length) {
    return { isAdminRequested: false, isManagerRequested: false };
  }

  const requestedStep = approval?.steps?.find((step) =>
    step.requests.find((request) => request.status === 'requested'),
  );

  const isAdminRequested = requestedStep?.requests.some(
    (request) => request.role === workflowRoles['membership:owner'],
  );

  const isManagerRequested = requestedStep?.requests.some(
    (request) => request.role === workflowRoles.manager,
  );

  const shouldShowApprovalButton =
    (isManager && isManagerRequested) || (isAdmin && isAdminRequested);

  const shouldShowForceApprovalButton = isAdmin && isManagerRequested;
  const shouldOverride = !isManager && isManagerRequested;

  return {
    isAdminRequested,
    isManagerRequested,
    shouldShowApprovalButton,
    shouldShowForceApprovalButton,
    shouldOverride,
  };
};
