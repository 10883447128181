import { useQuery } from '@tanstack/react-query';

import { Drawer, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import { fetchSearchAllUsers } from '@octopus/api';
import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../utils';

import { UsersTableComponents } from './components/table';
import { UserDetails, useUserDetailsControls } from './UserDetails';

export type UsersTableProps = {
  organizationId: string;
};
export function UsersTable({ organizationId }: UsersTableProps) {
  const dataGridProps = useDataGrid({
    filters: UsersTableComponents.searchFilters,
    pagination: {
      rowsPerPageOptions: [50, 100],
      initialRowsPerPageOptionIndex: 1,
    },
  });

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  const usersQuery = useQuery({
    queryKey: [organizationId, searchInput, paginationProps],
    queryFn: ({ signal }) => {
      return fetchSearchAllUsers(
        {
          pathParams: {
            organizationId: organizationId ?? '',
          },
          body: searchInput,
        },
        signal,
      ).then(({ data, ...response }) => ({
        ...response,
        data: data.filter(({ memberships }) => memberships.length > 0),
      }));
    },
    enabled: !!organizationId,
  });

  const {
    selectedUser,
    deselectUser,
    selectUser,
    selectNextUser,
    selectPreviousUser,
    afterUpdate,
  } = useUserDetailsControls(usersQuery, usersQuery.refetch);

  return (
    <>
      <Box
        paddingBottom={1.5}
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Box
          alignSelf="stretch"
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <DataGridToolbar
            filters={UsersTableComponents.searchFilters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            sx={{
              width: '100%',
            }}
          >
            {/* verticon goes here */}
          </DataGridToolbar>
        </Box>
      </Box>
      <DataFetching
        fetchResult={usersQuery}
        Loading={() => (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        )}
        Data={({ data }) => {
          const response = data;
          return (
            <Box mt={2}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.userId}
                  rows={response.data}
                  columns={UsersTableComponents.columns}
                  onRowClick={({ row }) => selectUser(row)}
                />
              ) : null}
            </Box>
          );
        }}
      />

      {selectedUser && (
        <Drawer
          anchor="right"
          open={!!selectedUser}
          onClose={deselectUser}
          elevation={2}
        >
          <UserDetails
            user={selectedUser.user}
            controls={{
              close: deselectUser,
              hasNext: selectedUser.hasNext,
              hasPrevious: selectedUser.hasPrevious,
              onNext: selectNextUser,
              onPrevious: selectPreviousUser,
              afterUpdate: afterUpdate,
            }}
          />
        </Drawer>
      )}
    </>
  );
}
