import { omit } from 'lodash';

import { Box, Tab, Tabs } from '@mui/material';

import {
  ContractEntry,
  ContractVacationsAccrualPeriodsEntry,
  VacationsScheduleList,
  VacationsScheduleSummary,
} from '@octopus/api';

import TabLabel from '../../../../modules/components/TabLabel';
import { getAvailablesVacations } from '../../[contractId]/new/utils';
import {
  shouldShowCalculatedVacations,
  shouldShowDeclinedVacations,
  shouldShowRequestedVacations,
} from '../../utils/status';
import { VacationsDetailsTabs } from '../../utils/types';

import { AvailabilityTab } from './AvailabilityTab';
import { CalculationsTab } from './CalculationsTab';
import { Controls, DrawerHeader } from './DrawerHeader';
import { HistoryTab } from './HistoryTab';
import { RequestsTab } from './RequestsTab';
import { vacationsDetailsTabsConfig } from './VacationsTabs';

export const VacationsDrawer = ({
  isManager,
  row,
  onSuccess,
  controls,
  contract,
  tab,
  setTab,
  contractAccrualPeriods,
  vacationsSchedule,
}: {
  isManager: boolean;
  onSuccess: () => Promise<void>;
  controls: Controls;
  contract: ContractEntry;
  tab: VacationsDetailsTabs;
  setTab: (tab: VacationsDetailsTabs) => void;
  row: VacationsScheduleSummary;
  contractAccrualPeriods: ContractVacationsAccrualPeriodsEntry;
  vacationsSchedule: VacationsScheduleList;
}) => {
  const availableVacations = getAvailablesVacations(
    contractAccrualPeriods?.accrualPeriods,
  );

  const requestedVacations = vacationsSchedule?.data.filter(({ status }) =>
    shouldShowRequestedVacations(status),
  );

  const calculatedVacations = vacationsSchedule?.data.filter(({ status }) =>
    shouldShowCalculatedVacations(status),
  );

  const historyVacations = vacationsSchedule?.data.filter(
    ({ status }) => !shouldShowDeclinedVacations(status),
  );

  const rejectedOrCanceledVacations = vacationsSchedule?.data.filter(
    ({ status }) => shouldShowDeclinedVacations(status),
  );

  vacationsDetailsTabsConfig.available.count = availableVacations?.filter(
    ({ daysAvailable }) => daysAvailable,
  )?.length;
  vacationsDetailsTabsConfig.requests.count = requestedVacations?.length;
  vacationsDetailsTabsConfig.calculations.count = calculatedVacations?.length;

  const tabs = isManager
    ? omit(vacationsDetailsTabsConfig, [VacationsDetailsTabs.calculations])
    : vacationsDetailsTabsConfig;

  return (
    <Box px={5}>
      <Box>
        <DrawerHeader controls={controls} contract={contract} />
      </Box>
      <Box width={536}>
        <Box>
          <Tabs
            value={tab}
            onChange={(_, newTab) => setTab(newTab)}
            textColor="inherit"
            data-testid="vacations-status-tabs"
            TabIndicatorProps={{
              sx: {
                bgcolor: 'primary.main',
              },
            }}
          >
            {Object.entries(tabs).map(([key, tabConfig]) => (
              <Tab
                key={key}
                value={key}
                icon={
                  <TabLabel
                    variant="body2"
                    isSelected={key === tab}
                    color="primary"
                    label={tabConfig.label}
                    count={tabConfig?.count > 0 ? tabConfig.count : undefined}
                  />
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box mt={3}>
          {tab === VacationsDetailsTabs.available && (
            <AvailabilityTab
              availableVacations={availableVacations}
              contractId={row.contractId}
              isManager={isManager}
            />
          )}
          {tab === VacationsDetailsTabs.requests && (
            <RequestsTab
              requests={requestedVacations}
              onSuccess={onSuccess}
              isManager={isManager}
            />
          )}
          {tab === VacationsDetailsTabs.calculations && (
            <CalculationsTab
              calculations={calculatedVacations}
              onSuccess={onSuccess}
            />
          )}
          {tab === VacationsDetailsTabs.history && (
            <HistoryTab
              history={historyVacations}
              rejectedOrCanceledVacations={rejectedOrCanceledVacations}
              isManager={isManager}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
