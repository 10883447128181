import { MouseEvent, useState } from 'react';

import { Popover, Typography } from '@mui/material';

export const usePopover = (): [
  React.FC<{ children: React.ReactNode }>,
  (event: React.MouseEvent<any>) => void,
  () => void,
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const Component: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography
        align="center"
        variant="body2"
        sx={{
          width: '240px',
          padding: '12px',
          backgroundColor: '#25252D',
          color: '#fff',
        }}
      >
        {children}
      </Typography>
    </Popover>
  );
  return [Component, handlePopoverOpen, handlePopoverClose];
};
