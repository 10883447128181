import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Button,
  Container,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  PayrollEntry,
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsPayroll,
  fetchGetPayroll,
  fetchGetPayrollInputsConfiguration,
} from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';

import Pencil from '../../../assets/pencil.svg';
import { PageAlert } from '../../../modules/components/PageAlert';
import { DataFetching } from '../../../modules/dataFetching';
import { LegalEntityContext } from '../../../modules/types';
import { AppContext } from '../../context';
import { SubmissionToast } from '../[period]/[type]/SubmissionToast';

import createBaseRPAInputConfiguration from './inputs/createBaseRpaInputConfiguration';
import RPASubmission from './inputs/RPASubmission';
import { RPANavigation } from './navigationUtils';
import { SelectLegalEntityDialog } from './RpaDialogs';

export default function EditSingleRpaPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { payrollId } = useParams<{
    payrollId: string;
  }>();
  const navigate = useNavigate();
  const { appContext } = useContext(AppContext);

  const [showSelectLegalEntityDialog, setShowSelectLegalEntityDialog] =
    useState(false);
  const [period, setPeriod] = useState<string>('');
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<string>('');
  const selectedLegalEntityRef = useRef<string>(selectedLegalEntity);
  const rpaHandlersRef = useRef({
    legalEntityChange: () =>
      console.log('Legal entity handler not initialized'),
  });
  const registerLegalEntityHandler = useCallback((handler: () => void) => {
    rpaHandlersRef.current.legalEntityChange = handler;
  }, []);

  // Effects
  useEffect(() => {
    selectedLegalEntityRef.current = selectedLegalEntity;
    rpaHandlersRef.current.legalEntityChange();
  }, [selectedLegalEntity]);

  const inputsConfiguration = useQuery({
    queryKey: ['rpa-fetch-inputs-configuration', organizationId, companyId],
    queryFn: () => {
      return fetchGetPayrollInputsConfiguration({
        pathParams: {
          organizationId,
          companyId,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    try {
      if (
        inputsConfiguration.isSuccess &&
        Object.values(inputsConfiguration.data.payload).filter(
          (input) => input.target === 'rpa',
        ).length === 0
      ) {
        inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
          organizationId,
          companyId,
          existingPayload: inputsConfiguration.data.payload,
        });
      }
    } catch (error) {
      console.error('Error creating base RPA input configuration', error);
    }
  }, [inputsConfiguration.isSuccess]);

  const payroll = useQuery({
    queryKey: ['rpa-fetch-inputs', organizationId, companyId, payrollId],
    queryFn: () => {
      return fetchGetPayroll({
        pathParams: {
          organizationId,
          companyId,
          payrollId,
        },
      });
    },
    refetchOnWindowFocus: true,
    enabled: !!organizationId && !!companyId && !!payrollId,
    refetchOnMount: 'always',
  });

  const dataFetchingContent = useMemo(
    () => (
      <DataFetching<{
        inputsConfiguration: PayrollInputsConfig;
        payrollEntry: PayrollEntry;
      }>
        containerSx={{ height: '100%' }}
        fetchResult={{
          results: {
            inputsConfiguration,
            payrollEntry: payroll,
          },
        }}
        Loading={() => (
          <Skeleton
            variant="rounded"
            width="100%"
            height="calc(100vh - 245px)"
          />
        )}
        Data={({ data }) => {
          const payrollInputsList = convertPayrollToInputsList({
            organizationId,
            companyId,
            payroll: payroll.data,
          });
          setSelectedLegalEntity(data.payrollEntry.legalEntityId);
          setPeriod(payroll.data.period);
          return (
            <Box
              sx={{
                px: '40px',
                display: 'flex',
                flexDirection: 'column',
                height: '80vh',
                gap: 2,
              }}
            >
              <RPASubmission
                organizationId={organizationId}
                companyId={companyId}
                period={payroll.data.period}
                type="rpa"
                data={payrollInputsList}
                config={data.inputsConfiguration}
                mode={'single'}
                selectedLegalEntityRef={selectedLegalEntityRef}
                onLegalEntityChange={registerLegalEntityHandler}
              />
              <SubmissionToast successMessage="As edições foram aplicadas e cálculo da folha foi atualizado." />
            </Box>
          );
        }}
        Error={() => {
          if (inputsConfiguration.error) {
            inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
              organizationId,
              companyId,
            });
          }
          return <GenericError />;
        }}
      />
    ),
    [
      inputsConfiguration.status,
      inputsConfiguration.data,
      inputsConfiguration.error,
      organizationId,
      companyId,
      payroll.data,
    ],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar sx={{ padding: 0 }}>
        <Button
          variant="text"
          color="primaryAlt"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            padding: 0,
            height: '26px',
          }}
          onClick={() => navigate(RPANavigation.homePagePath())}
        >
          Cancelar
        </Button>
      </Toolbar>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          data-testid="autonomo-page-header"
          px="40px"
        >
          <Typography variant="subtitle1" fontSize={'12px'}>
            Pagamentos para autônomos
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              height: '32px',
              '& img': {
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
              },
              '&:hover img': { opacity: 1 },
            }}
            onClick={() => {
              if (appContext.company.legalEntities.length > 1) {
                setShowSelectLegalEntityDialog(true);
              }
            }}
          >
            <Typography variant="h1" fontSize={'24px'}>
              {formatPeriodDate(period)}
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              •
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              {
                appContext.company.legalEntities.find(
                  (legalEntity) => legalEntity.id === selectedLegalEntity,
                )?.name
              }
            </Typography>
            {/* We should only show the pencil icon if there are more than one legal entity */}
            {appContext.company.legalEntities.length > 1 && (
              <Box
                component="img"
                src={Pencil}
                sx={{ height: '20px', width: '20px', padding: '0px' }}
              />
            )}
          </Box>
        </Box>
        {dataFetchingContent}
      </Container>
      <SelectLegalEntityDialog
        open={showSelectLegalEntityDialog}
        setOpen={setShowSelectLegalEntityDialog}
        selectedLegalEntity={selectedLegalEntity}
        setSelectedLegalEntity={setSelectedLegalEntity}
        legalEntities={appContext.company.legalEntities as LegalEntityContext[]}
      />
    </Box>
  );
}

function convertPayrollToInputsList({
  organizationId,
  companyId,
  payroll,
}: {
  organizationId: string;
  companyId: string;
  payroll: PayrollEntry;
}): PayrollInputsList {
  return {
    organizationId,
    companyId,
    period: payroll.period,
    type: 'rpa',
    payrolls: {
      [payroll.payrollId]: {
        name: payroll.workerData.name,
        inputs: {
          ...(payroll.inputs.events || {}),
          ...(payroll.inputs.rpa || {}),
          ...(payroll.inputs.records || {}),
        },
        contractId: payroll.contractId,
        employeeId: payroll.workerData.employeeId,
        personId: payroll.personId,
        dependents: {},
        // legalEntityId: payroll.legalEntityId,
      } as PayrollInputsPayroll,
    },
  };
}

function GenericError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Falha ao carregar tabela de lançamentos!"
        severity="error"
        showRetryMessage={true}
      />
    </Box>
  );
}
