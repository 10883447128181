import {
  Check,
  CloseOutlined,
  HourglassEmptyOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { PayrollEntry, VacationsScheduleEntry } from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import { Button as DSButton } from '@octopus/ui/design-system';

import { useVacationsGeneratePayroll } from '../../hooks/useVacationsGeneratePayroll';
import { useVacationsPayrollApprove } from '../../hooks/useVacationsPayrollApprove';
import {
  shouldShowPayrollApproval,
  shouldShowPayrollCreation,
  shouldShowSeeReceipt,
  shouldShowSendPayslip,
} from '../../utils/status';
import { BodyText } from '../Text';

import { PayrollSendPayslip } from './PayrollSendPayslip';

export const PayrollActions = (props: {
  vacationSchedule: VacationsScheduleEntry;
  payroll: PayrollEntry;
  onSuccess: () => Promise<void>;
}) => {
  const { vacationSchedule, payroll, onSuccess } = props;
  const { status, payrollId, payslipStatus } = vacationSchedule || {};
  const { onClickApprove, onClickReject, ConfirmPayroll, RejectPayroll } =
    useVacationsPayrollApprove(vacationSchedule, payroll, onSuccess);

  const { onGeneratePayroll, isLoading: isGeneratingPayroll } =
    useVacationsGeneratePayroll(vacationSchedule, onSuccess);

  const hasPayroll = !!payrollId;
  if (hasPayroll && !payroll) return null;

  return (
    <Box display="flex" flexDirection="column">
      <ConfirmPayroll />
      <RejectPayroll />
      <Box mt={2} display="flex" justifyContent="space-between">
        {hasPayroll && shouldShowPayrollApproval(status) && (
          <ApprovalActions
            vacationSchedule={vacationSchedule}
            onApprove={onClickApprove}
            onReject={onClickReject}
          />
        )}

        {shouldShowPayrollCreation(status) && (
          <GeneratePayrollAction
            onGeneratePayroll={onGeneratePayroll}
            isSubmitting={isGeneratingPayroll}
          />
        )}

        {(shouldShowSendPayslip(status, payslipStatus) ||
          shouldShowSeeReceipt(status, payslipStatus)) && (
          <PayrollSendPayslip
            vacationSchedule={vacationSchedule}
            payroll={payroll}
            onSuccess={onSuccess}
          />
        )}
      </Box>
    </Box>
  );
};

const ApprovalActions = (props: {
  vacationSchedule: VacationsScheduleEntry;
  onApprove: () => void;
  onReject: () => void;
}) => {
  const { vacationSchedule, onApprove, onReject } = props;
  const { approvalDeadline } = vacationSchedule;

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <BodyText>
          {`Limite para aprovação: ${formatDateBR(approvalDeadline)}`}
        </BodyText>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="end" gap={1}>
        <DSButton
          variantLayout="tiny"
          variantSemantic="secondary"
          onClick={onReject}
        >
          {'Recusar'}
          <CloseOutlined sx={{ ml: '4px', width: '16px', height: '16px' }} />
        </DSButton>
        <DSButton
          variantLayout="tiny"
          sx={{ ml: 1, minWidth: '100px', minHeight: '32px' }}
          onClick={onApprove}
        >
          {'Aprovar'}
          <Check sx={{ ml: '4px', width: '16px', height: '16px' }} />
        </DSButton>
      </Box>
    </Box>
  );
};

const GeneratePayrollAction = (props: {
  onGeneratePayroll: () => void;
  isSubmitting: boolean;
}) => {
  const { onGeneratePayroll, isSubmitting } = props;
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" mr={2}>
        <HourglassEmptyOutlined sx={{ color: '#616161', ml: 1, mr: 1.5 }} />
        <BodyText>
          O cálculo desta solicitação será gerado automáticamente, ou se
          preferir, usando o botão ao lado.
        </BodyText>
      </Box>
      <DSButton
        variantLayout="tiny"
        variantSemantic="secondary"
        onClick={onGeneratePayroll}
        sx={{ minWidth: '100px' }}
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Gerar cálculo
      </DSButton>
    </Box>
  );
};
