import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GroupVacationsConfiguration } from '@octopus/api';
import {
  groupVacationsErrorMessages,
  GroupVacationsRule,
} from '@octopus/vacations-types';
import { LABEL_MAP } from './fields';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

type Props = {
  config: GroupVacationsConfiguration;
  onChange: (updated: GroupVacationsConfiguration) => void;
};

export function GroupVacationsConfig({ config, onChange }: Props) {
  const entries = Object.entries(config.rules);

  const handleRuleChange = (ruleKey: string, field: string, value: any) => {
    const newRules = { ...config.rules };
    newRules[ruleKey] = {
      ...newRules[ruleKey],
      [field]: value,
    };

    onChange({ ...config, rules: newRules });
  };

  return (
    <Accordion
      className="borderless"
      defaultExpanded={false}
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: 1,
        border: 'none',
        height: '100%',
        width: '100%',
        px: 2,
        py: 1,
        boxSizing: 'border-box',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        <Box display="flex">
          <Typography variant="h3" mb={2}>
            Férias Coletivas
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box>
          <TableContainer sx={{ border: '1px solid #EDEDED', borderRadius: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Regra</TableCell>
                  <TableCell>Configuração</TableCell>
                  <TableCell>Parâmetros</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map(([ruleKey, rule]) => {
                  const { id, enabled, ...otherFields } = rule;
                  const ruleMessages =
                    groupVacationsErrorMessages[id as GroupVacationsRule];
                  const ruleDescription = ruleMessages?.description || id;
                  const ruleSummary = ruleMessages?.summary || id;

                  return (
                    <TableRow key={ruleKey}>
                      <TableCell>
                        <Tooltip title={ruleSummary}>
                          <Typography variant="body2" sx={{ cursor: 'help' }}>
                            {ruleDescription}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          size="small"
                          checked={enabled}
                          onChange={(e) =>
                            handleRuleChange(
                              ruleKey,
                              'enabled',
                              e.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {enabled &&
                          Object.entries(otherFields).map(
                            ([fieldName, fieldValue]) => {
                              if (fieldName === 'id' || fieldName === 'enabled')
                                return null;

                              const isNumber = typeof fieldValue === 'number';

                              return (
                                <TextField
                                  key={fieldName}
                                  label={LABEL_MAP[fieldName] || fieldName}
                                  type={isNumber ? 'number' : 'text'}
                                  size="small"
                                  InputLabelProps={{
                                    style: { fontSize: '0.85rem' },
                                  }}
                                  inputProps={{
                                    style: { fontSize: '0.85rem' },
                                  }}
                                  value={fieldValue ?? ''}
                                  onChange={(e) =>
                                    handleRuleChange(
                                      ruleKey,
                                      fieldName,
                                      isNumber
                                        ? Number(e.target.value)
                                        : e.target.value,
                                    )
                                  }
                                  sx={{ mr: 2, minWidth: 120 }}
                                />
                              );
                            },
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
