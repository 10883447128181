import React from 'react';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';

import { VacationsConfigurationDetails } from './configuration';

export function VacationsConfiguration({
  organizationId,
}: {
  organizationId: string;
}) {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="vacations-notifications-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Configuração de Férias</Typography>
        </Box>
        <Box >
          <VacationsConfigurationDetails organizationId={organizationId} />
        </Box>
      </Container>
    </Box>
  );
}
