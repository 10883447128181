import { CheckCircle } from '@mui/icons-material';

import { ContractDocumentAllowedContentType } from '@octopus/api';

import { SnackbarType } from '../../../../modules/hooks/snackbarContext';

import { ALLOWED_CONTENT_TYPES } from './types';

export const getErrorSnackbar = (message?: string): SnackbarType => ({
  isOpen: true,
  variant: 'error',
  Message: message || 'Ocorreu um erro, por favor tente novamente.',
  hasCloseAction: true,
});

export const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 2000,
  hasCloseAction: false,
});

export const getContentType = (
  file: File,
): ContractDocumentAllowedContentType => {
  const extension = `.${file.name.split('.').pop()?.toLowerCase()}`;
  return ALLOWED_CONTENT_TYPES[extension] || 'application/pdf';
};
