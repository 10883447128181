import { IconUserCheck } from '@tabler/icons-react';

import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumnHeaderParams } from '@mui/x-data-grid';

import { UserSummary } from '@octopus/api';
import {
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeDateRangeFilter,
  makeElementListFilter,
} from '@octopus/ui/data-grid';

import { UserManagementUtils } from './utils';

type Membership = UserSummary['memberships'][number];
type Contract = UserSummary['contracts'][number];

const renderColumnHeader = (params: GridColumnHeaderParams) => {
  return <Box>{params.field}</Box>;
};
function ColumnValue({ children }: { children: React.ReactNode }) {
  return <Box sx={{ py: 1 }}>{children}</Box>;
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Usuário(a)',
    renderHeader: renderColumnHeader,
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return <ColumnValue>{params.row.name}</ColumnValue>;
    },
  },
  {
    field: 'latestLoginDate',
    headerName: 'Último acesso',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      const { label, value } = UserManagementUtils.formatDatetime(
        params.row.latestLoginDate,
      );
      if (label === 'Nunca' || label === 'Hoje') {
        return <ColumnValue>{label}</ColumnValue>;
      }
      return (
        <ColumnValue>
          <Tooltip title={value} placement="top">
            <Box>{label}</Box>
          </Tooltip>
        </ColumnValue>
      );
    },
  },
  {
    field: 'contracts.active',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      const contracts: Contract[] = params.row.contracts;
      // active == at least one contract for the user in this organization is active
      const active = contracts.some((contract) => contract.active);

      return (
        <ColumnValue>
          <Box
            display="flex"
            alignItems="flexStart"
            flexDirection="row"
            gap={1.5}
          >
            <Tooltip
              title={`Vínculo ${active ? 'ativo' : 'inativo'}`}
              placement="top"
            >
              <IconUserCheck
                color={active ? '#0058DB' : '#BABABF'}
                width={16}
                height={16}
              />
            </Tooltip>
          </Box>
        </ColumnValue>
      );
    },
  },
  {
    field: 'memberships.type',
    headerName: 'Permissões',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      // only a single membership per organization
      const memberships: Membership[] = params.row.memberships;
      const [membership] = memberships;

      return (
        <ColumnValue>
          {membership
            ? UserManagementUtils.formatMembershipType(membership.type)
            : '-'}
        </ColumnValue>
      );
    },
  },
];

const searchFilters: FilterOptions = [
  makeElementListFilter({
    label: 'Situação do Vínculo',
    propertyToFilter: 'contracts.active',
    elements: ['true', 'false'],
    labels: {
      true: 'Ativo',
      false: 'Inativo',
    },
    isNested: true,
  }),
  makeElementListFilter({
    label: 'Permissões',
    propertyToFilter: 'memberships.type',
    elements: ['internal', 'owner'],
    labels: {
      internal: 'Padrão',
      owner: 'Administrador',
    },
    isNested: true,
  }),
  makeDateRangeFilter({
    label: 'Último acesso',
    propertyToFilter: 'latestLoginDate',
  }),
];

export const UsersTableComponents = {
  searchFilters,
  columns,
};
