import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';

import { usePopover } from '../../hooks/usePopover';

export const AlertWithPopover = ({
  value,
  variant,
  popoverContent,
}: {
  value: string;
  variant: 'warning' | 'error' | null;
  popoverContent: string;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const withStyles = variant
    ? (theme: any) => ({
        color: theme.palette[variant].main,
      })
    : undefined;

  return (
    <Box
      display="flex"
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography sx={withStyles} variant="body2">
        {value || '-'}
      </Typography>
      {variant && (
        <>
          <Box ml={1}>
            <WarningIcon sx={withStyles} />
          </Box>
          <WithPopover>{popoverContent}</WithPopover>
        </>
      )}
    </Box>
  );
};
