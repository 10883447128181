import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { VacationsScheduleSummary, useSendPayrollPayslip } from '@octopus/api';
import { Button } from '@octopus/ui/design-system';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { useVacationsGeneratePayroll } from '../../hooks/useVacationsGeneratePayroll';
import { shouldShowSendPayslip } from '../../utils/status';

export const PayrollActions = (props: {
  row: VacationsScheduleSummary;
  refetchAll: () => Promise<void>;
}) => {
  const {
    mutate: sendPayrollPayslip,
    isLoading,
    isSuccess,
  } = useSendPayrollPayslip();
  const { showSnackbar } = useSnackbar();
  const { contractId, sequence, status, payslipStatus } = props.row;
  const { onGeneratePayroll, isLoading: isGeneratingPayroll } =
    useVacationsGeneratePayroll(props.row, props.refetchAll);
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/vacations/${contractId}/receipt?sequence=${sequence}`);
  };

  const onSendPayrollPayslip = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    sendPayrollPayslip(
      {
        pathParams: {
          payrollId: props.row.payrollId,
          companyId: props.row.companyId,
          organizationId: props.row.organizationId,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            Message: 'Recibo enviado com sucesso',
            isOpen: true,
          });
        },
        onError: () => {
          showSnackbar({
            Message: 'Erro ao enviar recibo',
            variant: 'error',
            isOpen: true,
          });
        },
      },
    );
  };

  const handleGeneratePayroll = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    await onGeneratePayroll();
  };

  const buttonStyle = { whiteSpace: 'nowrap', color: 'secondary' };

  return (
    <Box display="flex" justifyContent="center">
      {status === vacationsScheduleStatuses.payrollCreationScheduled && (
        <Button
          variantSemantic="tertiary"
          onClick={handleGeneratePayroll}
          isLoading={isGeneratingPayroll}
          variantLayout="small"
          sx={buttonStyle}
        >
          Gerar cálculo
        </Button>
      )}
      {status === vacationsScheduleStatuses.payrollCreated && (
        <Button onClick={onClick} variantLayout="small">
          Revisar
        </Button>
      )}
      {status === vacationsScheduleStatuses.payrollApproved &&
        shouldShowSendPayslip(status, payslipStatus) &&
        !isSuccess && (
          <Button
            isLoading={isLoading}
            onClick={onSendPayrollPayslip}
            variantLayout="small"
            disabled={isLoading}
            variantSemantic="tertiary"
            sx={buttonStyle}
          >
            Enviar recibo
          </Button>
        )}
    </Box>
  );
};
