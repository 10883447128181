import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, Theme, Typography, alpha } from '@mui/material';

import { useFFlags } from '../../fflags';
import { OrderedApps, SidebarApp } from '../types';

export function SidebarAppSelector({
  apps,
  open,
}: {
  apps: SidebarApp[];
  open: boolean;
}) {
  const { FFlags } = useFFlags();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      pt={3}
    >
      {OrderedApps.map((appName, i) => {
        const app = apps.find((app) => app.title === appName);
        if (!app) return null;

        if (app.fflag) {
          return (
            <FFlags key={i} {...{ [app.fflag]: true }}>
              <SidebarAppSelectorItem
                app={app}
                open={open}
                isExpanded={expandedIndex === i}
                onExpand={() => handleExpand(i)}
              />
            </FFlags>
          );
        }
        return (
          <SidebarAppSelectorItem
            key={i}
            app={app}
            open={open}
            isExpanded={expandedIndex === i}
            onExpand={() => handleExpand(i)}
          />
        );
      })}
    </Box>
  );
}

function SidebarAppSelectorItem({
  isChild,
  app,
  open,
  isExpanded,
  onExpand,
}: {
  isChild?: boolean;
  app: SidebarApp;
  open: boolean;
  isExpanded: boolean;
  onExpand: () => void;
}) {
  const { FFlags } = useFFlags();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { title, Icon, path, children } = app;

  const selected = useMemo(() => {
    return pathname.startsWith(path);
  }, [pathname, path]);

  const hasSubItems = children?.length;
  const collapseParent = () => !isChild && !hasSubItems && onExpand();
  const expandMenuOrNavigate = () =>
    hasSubItems ? onExpand() : navigate(path);

  const handleClick = () => {
    collapseParent();
    expandMenuOrNavigate();
  };

  // If the item has subitems, we want to keep the parent active only when the menu is closed
  const shouldKeepActiveWhenClosed = hasSubItems && !open;
  const shouldKeepActive = !hasSubItems || shouldKeepActiveWhenClosed;

  return (
    <>
      <Button
        onClick={handleClick}
        sx={(theme: Theme) => ({
          height: '40px',
          mx: 1,
          mb: 0.25,
          padding: '0',
          border: '0',
          minWidth: '0',
          justifyContent: 'flex-start',
          color: selected
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
          backgroundColor:
            selected && shouldKeepActive
              ? alpha(theme.palette.strokes.light, 0.4)
              : alpha(theme.palette.background.paper, 0.4),
          '&:hover': {
            backgroundColor: alpha(theme.palette.strokes.light, 0.4),
          },
          '&:active': {
            backgroundColor: alpha(theme.palette.strokes.heavy, 0.4),
          },
        })}
      >
        {isChild && <LineWithCircle isActive={true} />}
        <Box
          padding={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            className="small"
            sx={(theme: Theme) => ({
              color: selected
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            })}
          />
        </Box>
        {open && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography
              noWrap
              variant="body2"
              sx={{
                fontWeight: selected ? 'bold' : 'normal',
              }}
            >
              {title}
            </Typography>
            {children?.length > 0 && (
              <Box
                mr={1}
                display="flex"
                alignItems="center"
                color="text.secondary"
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </Box>
            )}
          </Box>
        )}
      </Button>
      {children?.length > 0 && (
        <Collapse in={isExpanded && open} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              background: 'linear-gradient(to bottom, #f0f0f0, #e0e0e0)',
              backgroundPosition: '26px 0',
              backgroundRepeat: 'repeat-y ',
              backgroundSize: '1px 100%',
            }}
          >
            {children.map((child, index) => {
              console.log(child.fflag);
              if (child.fflag) {
                return (
                  <FFlags key={index} {...{ [child.fflag]: true }}>
                    <SidebarAppSelectorItem
                      key={index}
                      app={child}
                      open={open}
                      isChild
                      isExpanded={isExpanded}
                      onExpand={onExpand}
                    />
                  </FFlags>
                );
              }
              return (
                <SidebarAppSelectorItem
                  key={index}
                  app={child}
                  open={open}
                  isChild
                  isExpanded={isExpanded}
                  onExpand={onExpand}
                />
              );
            })}
          </Box>
        </Collapse>
      )}
    </>
  );
}

const LineWithCircle = ({ isActive }: { isActive: boolean }) => (
  <Box
    sx={{
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      flexShrink: 0,
      backgroundColor: isActive ? '#616161' : 'transparent',
      ml: 2,
    }}
  />
);
