import { Dialog } from '@mui/material';

import { ApprovalConfirmationModal } from './modals/ApprovalConfirmationModal';
import { CancelConfirmationModal } from './modals/CancelConfirmationModal';
import { CreateRPAsModal } from './modals/CreateRPAsModal';
import { PasteCPFListModal } from './modals/PasteCPFListModal';
import { PeriodApprovalConfirmationModal } from './modals/PeriodApprovalConfirmationModal';
import { SelectLegalEntityModal } from './modals/SelectLegalEntityModal';
import { SelectPeriodModal } from './modals/SelectPeriodModal';
import { LegalEntityContext } from '../../../modules/types';

export function ApprovalDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <ApprovalConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}

export function CancelDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="cancel-payroll-modal"
    >
      <CancelConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}

export function SelectPeriodDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  mode,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  mode?: 'edit' | 'create' | 'approve';
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      {['create', 'edit'].includes(mode) ? (
        <SelectPeriodModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
          isEditing={mode === 'edit'}
        />
      ) : (
        <PeriodApprovalConfirmationModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
        />
      )}
    </Dialog>
  );
}

export function SelectLegalEntityDialog({
  open,
  setOpen,
  selectedLegalEntity,
  setSelectedLegalEntity,
  legalEntities,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedLegalEntity: string;
  setSelectedLegalEntity: (legalEntity: string) => void;
  legalEntities: LegalEntityContext[];
}) {
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <SelectLegalEntityModal
        close={() => setOpen(false)}
        selectedLegalEntity={selectedLegalEntity}
        setSelectedLegalEntity={setSelectedLegalEntity}
        legalEntities={legalEntities}
      />
    </Dialog>
  );
}

export function CreateRPAsDialog({
  open,
  setOpen,
  onClick,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick: () => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <CreateRPAsModal onClick={onClick} close={() => setOpen(false)} />
    </Dialog>
  );
}

export function PasteCPFListDialog({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (cpfList: string[]) => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <PasteCPFListModal onSubmit={onSubmit} close={() => setOpen(false)} />
    </Dialog>
  );
}
