import { useNavigate } from 'react-router-dom';

import { ChronoUnit, LocalDate } from '@js-joda/core';

import { Box, Button, Typography } from '@mui/material';

import { VacationsAccrualPeriodEntry } from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';

import { ExpiredIcon } from '../ExpiredIcon';
import { VacationInfoTags, VacationInfoTagsVariants } from '../Status';

import { DrawerCard } from './DrawerCard';

export const AvailabilityTab = ({
  availableVacations,
  contractId,
  isManager,
}: {
  availableVacations: VacationsAccrualPeriodEntry[];
  contractId: string;
  isManager: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box px={0.5}>
        {availableVacations.length > 0 &&
          availableVacations?.map(
            (
              {
                concessionPeriod,
                startDate,
                endDate,
                daysAvailable,
                daysAcquired,
                maximumAvailableDaysForWorker,
                lost,
              },
              idx,
            ) => {
              const endOfConcessionPeriod = LocalDate.parse(
                concessionPeriod?.endDate,
              );
              const daysUntilAvailable = LocalDate.now().until(
                LocalDate.parse(endDate),
                ChronoUnit.DAYS,
              );
              const daysUntilExpiration = LocalDate.now().until(
                endOfConcessionPeriod,
                ChronoUnit.DAYS,
              );
              const hasAvailableVacations = daysAvailable > 0;
              const canScheduleVacations =
                !lost?.reason && (daysAvailable > 0 || daysAcquired > 0);

              const expired = endOfConcessionPeriod.isBefore(LocalDate.now());
              const statusMap: {
                [key: string]: {
                  text: string | React.ReactNode;
                  variant: VacationInfoTagsVariants;
                  days: number;
                };
              } = {
                daysUntilAvailable: {
                  text: `Disponível em ${daysUntilAvailable} dias`,
                  variant: 'default',
                  days: maximumAvailableDaysForWorker,
                },
                daysUntilExpiration: {
                  text: `Vence em ${daysUntilExpiration} dias`,
                  variant: 'warning',
                  days: daysAvailable,
                },
                expired: {
                  text: (
                    <Box
                      component="span"
                      display="flex"
                      flexDirection="row"
                      justifyContent="start"
                      sx={{ fontWeight: 500 }}
                      mt={0.5}
                    >
                      <Box component="span" display="flex" py={0.5}>
                        <ExpiredIcon />
                        {`A data limite para concessão venceu em ${formatDateBR(concessionPeriod.endDate)},
                      portanto configurará pagamento de férias em dobro.`}
                      </Box>
                    </Box>
                  ),
                  variant: 'error',
                  days: daysAvailable,
                },
              };
              const { text, days, variant } = expired
                ? statusMap.expired
                : hasAvailableVacations
                  ? statusMap.daysUntilExpiration
                  : statusMap.daysUntilAvailable;

              const Alert = () => (
                <VacationInfoTags longText variant={variant} text={text} />
              );
              return (
                <DrawerCard key={startDate}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Typography variant="h4">{`${days} dias`}</Typography>
                        <Typography
                          fontSize={12}
                          variant="body1"
                          component="div"
                        >
                          {`Aquisitivo: ${formatDateBR(startDate)} a ${formatDateBR(endDate)}`}
                        </Typography>
                        <Typography
                          fontSize={12}
                          variant="body1"
                          component="div"
                        >
                          {`Concessivo: ${formatDateBR(concessionPeriod.startDate)} a ${formatDateBR(concessionPeriod.endDate)}`}
                        </Typography>
                        {!expired && (
                          <Box mt={0.5}>
                            <Alert />
                          </Box>
                        )}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        {!isManager && canScheduleVacations && idx === 0 && (
                          <Button
                            variant="outlined"
                            sx={(theme) => ({
                              border: `1px solid ${theme.palette.strokes.light}`,
                              paddingX: theme.spacing(1.5),
                              paddingY: theme.spacing(1),
                              color: theme.palette.text.secondary,
                              ':hover': {
                                border: `1px solid ${theme.palette.strokes.light}`,
                              },
                            })}
                            onClick={() =>
                              navigate(
                                `/vacations/${contractId}/new?startDate=${concessionPeriod?.startDate}`,
                              )
                            }
                          >
                            {'Agendar'}
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Box mt={1} display="flex">
                      {expired && <Alert />}
                    </Box>
                  </Box>
                </DrawerCard>
              );
            },
          )}
      </Box>
    </Box>
  );
};
