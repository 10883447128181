import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Box, Typography } from '@mui/material';

import { usePopover } from '../hooks/usePopover';

export const ExpiredIcon = ({
  totalDaysAvailable,
}: {
  totalDaysAvailable?: number;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const overdueVacationCount = Math.ceil(totalDaysAvailable / 30);
  return (
    <>
      <Box
        display="flex"
        px={1}
        py={0.5}
        borderRadius={0.5}
        sx={(theme) => ({
          color: theme.palette.error.lowEmphasis,
          bgcolor: theme.palette.background.error,
        })}
        aria-haspopup="true"
        component="span"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <LocalAtmIcon />

        {totalDaysAvailable && (
          <Typography
            ml={0.5}
            variant="body2"
            component="span"
            sx={(theme) => ({ color: theme.palette.error.lowEmphasis })}
          >
            {overdueVacationCount}
          </Typography>
        )}
      </Box>
      <WithPopover>
        {'Férias vencidas, configura pagamento de férias em dobro.'}
      </WithPopover>
    </>
  );
};
