import React, { useState } from 'react';

import { DeleteOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import {
  ContractDocumentEntry,
  useDeleteContractDocument,
  useDownloadContractDocuments,
  useSearchContractDocuments,
} from '@octopus/api';
import {
  DataGrid,
  DataGridToolbar,
  useDataGrid,
  useSearch,
} from '@octopus/ui/data-grid';

import { BackButton } from '../../../../modules/components/BackButton';
import { PageAlert } from '../../../../modules/components/PageAlert';
import { SnackbarType } from '../../../../modules/hooks/snackbarContext';

import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { getErrorSnackbar, getSuccessSnackbar } from './utils';

export function DocumentsTable({
  searchContractDocumentsQuery,
  organizationId,
  contractId,
  columns,
  downloadDocumentsMutation,
  deleteDocumentMutation,
  showSnackbar,
  dataGridProps,
  onRefresh,
  onUpload,
}: {
  searchContractDocumentsQuery: ReturnType<typeof useSearchContractDocuments>;
  organizationId: string;
  contractId: string;
  columns: GridColDef<ContractDocumentEntry>[];
  downloadDocumentsMutation: ReturnType<typeof useDownloadContractDocuments>;
  deleteDocumentMutation: ReturnType<typeof useDeleteContractDocument>;
  showSnackbar: (snackbar: SnackbarType) => void;
  dataGridProps: ReturnType<typeof useDataGrid>;
  onRefresh: (searchTerm?: string) => void;
  onUpload: () => void;
}) {
  const { isLoading, data, isError, error } = searchContractDocumentsQuery;
  const searchProps = useSearch();
  const { sortingProps, paginationProps, selectionProps, filteringProps } =
    dataGridProps;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const hasSelection =
    selectionProps.included.length > 0 || selectionProps.allSelected;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (ids: string[]) => {
    showSnackbar(getSuccessSnackbar('Seu download começará em breve'));

    downloadDocumentsMutation.mutate({
      pathParams: {
        organizationId,
        contractId,
      },
      body: {
        fileIds: ids,
      },
    });
    handleClose();
  };

  const handleDelete = async (ids: string[]) => {
    setIsDeleting(true);
    try {
      await Promise.all(
        ids.map((id) =>
          deleteDocumentMutation.mutateAsync({
            pathParams: {
              organizationId,
              contractId,
              documentId: id,
            },
          }),
        ),
      );

      await new Promise((resolve) => setTimeout(resolve, 4000));

      onRefresh(searchProps.searchTerm);

      const pluralIndicator = ids.length > 1 ? 's' : '';
      const snackbarDeleteMessage = `Documento${pluralIndicator} excluído${pluralIndicator}`;
      showSnackbar(getSuccessSnackbar(snackbarDeleteMessage));
    } catch (error) {
      console.error('Erro ao excluir documentos:', error);
      showSnackbar(getErrorSnackbar());
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      handleClose();
    }
  };

  const openDeleteDialog = (ids: string[]) => {
    setSelectedIds(ids);
    setDeleteDialogOpen(true);
  };

  if (isError) {
    console.error(
      `Failed to load contract documents: ${JSON.stringify(error)}`,
    );
    return (
      <>
        <BackButton destination="/people" />
        <PageAlert
          message="Erro ao carregar documentos do contrato"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  const columnsWithActions = [
    ...columns,
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ContractDocumentEntry>) => (
        <DocumentRowMenu
          onDownload={() => handleDownload([params.row.id])}
          onDelete={() => openDeleteDialog([params.row.id])}
        />
      ),
    },
  ];

  const selectedItems = selectionProps.allSelected
    ? (data?.data || [])
        .map((doc) => doc.id)
        .filter((id) => !selectionProps.excluded.includes(id))
    : selectionProps.included;

  return (
    <Box>
      <Box
        paddingBottom={1.5}
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Box
          alignSelf="stretch"
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          gap={2}
        >
          <DataGridToolbar
            filters={[]}
            searchProps={searchProps}
            filteringProps={filteringProps}
            sx={{
              width: '100%',
            }}
          />
          <Box display="flex" gap={2}>
            {hasSelection && (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    minWidth: '129px',
                    fontWeight: 600,
                  }}
                >
                  Mais ações
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <DocumentActionsMenu
                    selectedIds={selectedItems}
                    onDownload={() => handleDownload(selectedItems)}
                    onDelete={() => openDeleteDialog(selectedItems)}
                    onClose={handleClose}
                  />
                </Menu>
              </>
            )}
            <Button
              variant="contained"
              size="medium"
              color="primaryAlt"
              onClick={onUpload}
              sx={{
                minWidth: '174px',
                fontWeight: 600,
              }}
            >
              Adicionar documento
            </Button>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton variant="rounded" height="70vh" />
      ) : (
        <DataGrid
          sortingProps={sortingProps}
          paginationProps={paginationProps}
          selectionProps={selectionProps}
          totalRowCount={data?.total || 0}
          getRowId={(row) => row.id}
          rows={data?.data || []}
          columns={columnsWithActions}
          emptyMessage="Nenhum documento encontrado."
        />
      )}

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        plural={selectedIds.length > 1}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDelete(selectedIds)}
        isLoading={isDeleting}
      />
    </Box>
  );
}

function DocumentActionsMenu({
  onDownload,
  onDelete,
  onClose,
  selectedIds = [],
}: {
  onDownload: () => void;
  onDelete: () => void;
  onClose: () => void;
  selectedIds?: string[];
}) {
  const countTag = selectedIds.length > 1 && (
    <Box
      sx={{
        px: 1,
        py: 0,
        borderRadius: '100px',
        backgroundColor: 'strokes.light',
        height: 20,
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" color="text.primary">
        {selectedIds.length}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0,
        boxSizing: 'border-box',
        justifyContent: 'stretch',
      }}
    >
      <MenuItem
        onClick={() => {
          onDownload();
          onClose();
        }}
        sx={{
          p: 2,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          justifyContent: 'flex-start',
        }}
      >
        Download
        {countTag}
      </MenuItem>
      <MenuItem
        onClick={() => {
          onDelete();
          onClose();
        }}
        sx={{
          p: 2,
          color: 'error.main',
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <DeleteOutlined fontSize="medium" color={'error'} />
        Excluir documento{selectedIds.length > 1 ? 's' : ''}
        {countTag}
      </MenuItem>
    </Box>
  );
}

function DocumentRowMenu({
  onDownload,
  onDelete,
}: {
  onDownload: () => void;
  onDelete: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <DocumentActionsMenu
          selectedIds={[]}
          onDownload={onDownload}
          onDelete={onDelete}
          onClose={handleClose}
        />
      </Popover>
    </Box>
  );
}
