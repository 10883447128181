import {
  vacationsScheduleStatusIndex as statusIndex,
  vacationsPayslipStatuses,
} from '@octopus/vacations-types';
const {
  payrollApproved,
  payrollCreationScheduled,
  payrollCreated,
  created,
  approved,
  rejected,
  contractTerminated,
  waitingApproval,
} = statusIndex;

export type VacationsStatusesIndex = keyof typeof statusIndex;

export const shouldShowRequestedVacations = (
  status: VacationsStatusesIndex,
): boolean => {
  return statusIndex[status] >= created && statusIndex[status] <= approved;
};

export const shouldShowCalculatedVacations = (
  status: VacationsStatusesIndex,
): boolean => {
  return (
    statusIndex[status] >= payrollCreationScheduled &&
    statusIndex[status] <= payrollApproved
  );
};

export const shouldShowPayrollApproval = (
  status: VacationsStatusesIndex,
): boolean => {
  return statusIndex[status] === payrollCreated;
};

export const shouldShowPayrollCreation = (
  status: VacationsStatusesIndex,
): boolean => {
  return statusIndex[status] === payrollCreationScheduled;
};

export const shouldShowSendPayslip = (
  status: VacationsStatusesIndex,
  payslipStatus: string,
): boolean => {
  return (
    statusIndex[status] === payrollApproved &&
    (!payslipStatus || payslipStatus === vacationsPayslipStatuses.pending)
  );
};

export const shouldShowSeeReceipt = (
  status: VacationsStatusesIndex,
  payslipStatus: string,
): boolean => {
  return (
    statusIndex[status] === payrollApproved &&
    payslipStatus === vacationsPayslipStatuses.sent
  );
};

export const shouldShowDeclinedVacations = (
  status: VacationsStatusesIndex,
): boolean => {
  return (
    statusIndex[status] >= rejected && statusIndex[status] <= contractTerminated
  );
};

export const shouldShowApprovalList = (
  status: VacationsStatusesIndex,
): boolean => {
  return (
    statusIndex[status] >= waitingApproval && statusIndex[status] <= approved
  );
};

export const shouldShowApprovalActions = (
  status: VacationsStatusesIndex,
): boolean => {
  return statusIndex[status] === waitingApproval;
};

export const shouldShowReceipt = (status: VacationsStatusesIndex): boolean => {
  return (
    statusIndex[status] === payrollCreated ||
    statusIndex[status] === payrollApproved
  );
};

export const isApprovedScheduledVacation = (
  status: VacationsStatusesIndex,
): boolean => {
  return (
    statusIndex[status] >= approved && statusIndex[status] <= payrollApproved
  );
};
