import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  PayrollEntry,
  VacationsScheduleEntry,
  VacationsScheduleSummary,
} from '@octopus/api';
import { formatDateBR, formatMoney } from '@octopus/formatters';

import { BodyText, DetailText, LinkText } from '../Text';

export const ScheduleCardBody = (props: {
  summary: VacationsScheduleEntry | VacationsScheduleSummary;
  payroll?: PayrollEntry;
}) => {
  const navigate = useNavigate();
  const { summary, payroll } = props;
  const { daysSold, thirteenthAdvance, accrualPeriod } = summary;

  const hasPayroll = payroll !== undefined;
  const handlePayrollClick = () => {
    navigate(
      `/vacations/${summary.contractId}/receipt?sequence=${summary.sequence}`,
    );
  };

  return (
    <Box my={2} display="flex" justifyContent="space-between">
      <Box width="60%">
        <BodyText>{`Abono`}</BodyText>
        <BodyText>{`Adiantamento da parcela do 13º`}</BodyText>
        <BodyText>{`Periodo aquisitivo`}</BodyText>
        {accrualPeriod?.concessionPeriod && (
          <BodyText>{`Periodo concessivo`}</BodyText>
        )}
        {hasPayroll && <BodyText>{`Valor do pagamento`}</BodyText>}
      </Box>

      <Box width="40%" textAlign="left">
        <DetailText>{`${daysSold || 0} dias`}</DetailText>
        <DetailText>{thirteenthAdvance ? 'Sim' : 'Não'}</DetailText>
        <DetailText>
          {`${formatDateBR(accrualPeriod.startDate)} a ${formatDateBR(accrualPeriod.endDate)}`}
        </DetailText>
        {accrualPeriod?.concessionPeriod && (
          <DetailText>
            {`${formatDateBR(accrualPeriod?.concessionPeriod?.startDate)} a ${formatDateBR(accrualPeriod?.concessionPeriod?.endDate)}`}
          </DetailText>
        )}
        {hasPayroll && (
          <LinkText onClick={handlePayrollClick}>
            {`${formatMoney(payroll?.outputs?.netPay?.total)}`}
          </LinkText>
        )}
      </Box>
    </Box>
  );
};
